<template>
  <div class="client-manager">
    <els-bread class="_bread"></els-bread>
    <div class="client-select _tip">
      <el-select v-model="value" :placeholder="lang.selTerType">
        <el-option
          v-for="item in options"
          :key="item.deviceTypeName"
          :label="item.deviceTypeName"
          :value="item.id.id"
        >
        </el-option>
      </el-select>
      <div
        style="
          flex: 1;
          padding: 7px;
          background: #fff;
          display: flex;
          margin-left: 2%;
          justify-content: space-around;
        "
      >
        <div class="client-s">
          <span>{{ lang.terTotal }}</span>
          <span class="num">{{ total }}</span>
        </div>
        <div class="client-s">
          <span class="circle-green"></span>
          <span>{{ lang.terOnline }}</span>
          <span class="num">{{ online }}</span>
        </div>
        <div class="client-s">
          <span class="circle-grey"></span>
          <span>{{ lang.terOff }}</span>
          <span class="num">{{ outline }}</span>
        </div>
      </div>
    </div>
    <div class="_header client-index">
      <span>
        <el-button size="small" type="primary" @click="addClient">{{
          lang.addTer
        }}</el-button>
      </span>
      <span>
        <el-select v-model="sValue" clearable size="small" style="margin-right:10px;" :placeholder="lang.state">
          <el-option v-for="item in dicts.SYSTEM_COMPANY_STATUS" :key="item.value" :value="item.value" :label="item.label "></el-option>
        </el-select>
        <el-input
          v-model="nValue"
          size="small"
          style="width: 216px"
          :placeholder="lang.terNumName"
          prefix-icon="el-icon-search"
        ></el-input>
      </span>
    </div>
    <div class="_content has-tip">
      <els-table
        ref="cTable"
        :column="column"
        :onload="onload"
        :table="{ rowKey: 'id' }"
        :menuWidth="'240px'"
      >
        <template v-slot:menu="{ row }">
          <span @click="rowView(row)" style="color: #409eff">{{
            lang.view
          }}</span>
          <span class="client-line"></span>
          <span
            @click="rowDelete(row)"
            :style="{ color: row.source === '1' ? '#dddddd' : 'red' }"
            >{{ lang.delete }}</span
          >
          <span class="client-line"></span>
          <span @click="rowConfirm(row)" style="color: #409eff">{{
            lang.authen
          }}</span>
        </template>
      </els-table>
    </div>
    <client-build
      ref="clientDialog"
      :clientColumn="clientColumn"
      :data="cData"
      @setSuccess="setOk"
    ></client-build>
    <client-drawer
      ref="clientDrawer"
      :data="clientData"
      :tabs="tabs"
      :deviceTypes="deviceTypes"
      @isClose="showClose"
    ></client-drawer>
    <title-drawer
      ref="titleDialog"
      :data="titleData"
      :elsData="elsTitle"
      :rowData="rowD"
      @add="confCredent"
    ></title-drawer>
  </div>
</template>
<script>
import mixinsDicts from "@/mixins/dict.js";
import clientBuild from "../components/clientManage/client-build.vue";
import elsTable from "../../components/els-table/main.vue";
import clientDrawer from "../components/clientManage/client-drawer/main.vue";
import {
  getTenantDevices,
  getDeviceType,
  delDevice,
  Count,
} from "@/api/manager/client-manage.js";
import titleDrawer from "../components/clientType/title-drawer.vue";
import elsBread from "../../components/els-breadcrumb/main.vue";
import { mapState } from "vuex";
import { timesort } from "../../utils/client";
import { getDict } from "@/api/com/iot-dict.js";
import {
  saveDeviceCredentials,
  getDeviceCredentialsByDeviceId,
} from "@/api/manager/client-manage.js";
import mixinsI18n from "@/mixins/i18n.js";
export default {
  name: "client-manager",
  mixins: [mixinsDicts, mixinsI18n],
  components: { clientBuild, elsTable, elsBread, clientDrawer, titleDrawer },
  data: () => {
    return {
      rowD: {},
      dicts: {
        SYSTEM_COMPANY_STATUS: [],
        SYSTEM_SOURCETYPE: [],
        accessType: [],
        networkingMode: [],
        protocol: [],
        dataType: [],
      },
      // options: [],
      value: "",
      nValue: "",
      sValue:"",
      cData: { title: "", label: 0, rowData: [] },
      clientData: {},
      total: 0,
      online: 0,
      outline: 0,
      columnBread: [],
      deviceId: "",
      deviceTypes: [],
      credential: "",
      token: "", // 令牌未修改，需要给出提示
      tokenId: "", // 获取认证的id信息
      show: true, // true 显示访问令牌  false 显示RSA公钥
      PAGE_NAME: "clientManagePage",
    };
  },
  computed: {
    ...mapState(["breadMenus"]),
    column() {
      const name = {
        prop: "code",
        label: this.lang.terNum,
        minWidth: 200,
      };
      const code = {
        prop: "name",
        label: this.lang.terName,
        minWidth: 200,
      };
      const type = {
        prop: "typeName",
        label: this.lang.terType,
        minWidth: 120,
      };
      const advice = {
        prop: "protocol",
        label: this.lang.accPro,
        dict: this.dicts.protocol,
        minWidth: 130,
      };
      const dataType = {
        prop: "dataType",
        label: this.lang.dataFormat,
        dict: this.dicts.dataType,
        minWidth: 100,
      };
      const source = {
        prop: "source",
        label: this.lang.source,
        dict: this.dicts.SYSTEM_SOURCETYPE,
        minWidth: 70,
      };
      const status = {
        prop: "status",
        label: this.lang.state,
        dict: this.dicts.SYSTEM_COMPANY_STATUS,
        minWidth: 100,
      };
      const endTime = {
        prop: "lastOnlineTime",
        label: this.lang.lastOn,
        valueType: "date",
        minWidth: 160,
      };
      // console.log(this.dicts.SYSTEM_SOURCETYPE);
      return [name, code, type, advice, dataType, source, status, endTime];
    },
    elsTitle() {
      const dict = this.dicts;
      const row = this.rowD;
      const d = [
        {
          tag: "div",
          title: this.lang.basicPro,
          cls: [
            {
              label: this.lang.authMethod,
              tag: "el-select",
              prop: "credentialsType",
              cls: dict.accessType,
              disabled: true,
              hooks: {
                updated() {
                  const v = this.$("value");
                  if (!!v && v.toUpperCase().indexOf("ACCESS") > -1) {
                    this.context.source.credentialsId.$("vif", true);
                    this.context.source.credentialsValue.$("vif", false);
                  } else {
                    this.context.source.credentialsId.$("vif", false);
                    this.context.source.credentialsValue.$("vif", true);
                  }
                },
              },
            },
            {
              label: this.lang.accToken,
              tag: "el-input",
              prop: "credentialsId",
              required: true,
              rules: [{ max: 80, message: this.lang.max80, trigger: "blur" }],
              hooks: {
                created() {
                  const v = this.context.source.formData.credentialsType;
                  const bool = !!v && v.toUpperCase().indexOf("ACCESS") > -1;
                  this.context.source.credentialsId = this;
                  this.$("vif", bool);
                },
              },
            },
            {
              label: this.lang.RSAKey,
              tag: "el-input",
              prop: "credentialsValue",
              type: "textarea",
              required: true,
              rules: [{ max: 50, message: this.lang.max50, trigger: "blur" }],
              hooks: {
                created() {
                  const v = this.context.source.formData.credentialsType;
                  const bool = !!v && v.toUpperCase().indexOf("ACCESS") > -1;
                  this.context.source.credentialsValue = this;
                  this.$("vif", !bool);
                },
              },
            },
          ],
        },
      ];
      return d;
    },
    clientColumn() {
      const tab = [
        {
          label: this.lang.terNum,
          tag: "el-input",
          prop: "code",
          required: true,
          // rules: [
          //   {
          //     pattern: /^\w{0,20}$/,
          //     message: this.lang.terNumMsg,
          //     trigger: "blur",
          //   },
          // ],
        },
        {
          label: this.lang.terName,
          tag: "el-input",
          prop: "name",
          // rules: [
          //   {
          //     pattern: /^\w{0,20}$/,
          //     message: "请输入20位以内的字母，数字，_组合",
          //     trigger: "blur",
          //   },
          // ],
        },
        {
          tag: "el-select",
          label: this.lang.terType,
          placeholder: this.lang.pleaseSelect,
          required: true,
          trigger: "change",
          prop: "netStyle",
          cls: this.deviceTypes.map((item) => ({
            value: item.id.id,
            label: item.deviceTypeName,
          })),
        },
        {
          label: this.lang.SIMNum,
          tag: "el-input",
          prop: "simNumber",
          // rules: [
          //   {
          //     pattern:
          //       /^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/,
          //     message: "请输入正确的卡号",
          //     trigger: "blur",
          //   },
          //   { max: 11, message: "长度不能超过11个字符", trigger: "blur" },
          // ],
        },
        {
          label: "",
          tag: "el-checkbox",
          prop: "gateway",
          props: { label: this.lang.isGateway },
          value: false,
        },
      ];
      return tab;
    },
    options() {
      const types = this.deviceTypes || [];
      const d = { deviceTypeName: this.lang.allTerType, id: { id: "" } };
      return [d, ...types];
    },
    tabs() {
      const data = [
        { label: this.lang.detInfor, name: "1" },
        { label: this.lang.attribute, name: "2" },
        { label: this.lang.latestTel, name: "3" },
        { label: this.lang.warning, name: "4" },
        { label: this.lang.event, name: "5" },
        { label: this.lang.relation, name: "6" },
        { label: this.lang.auditLog, name: "7" },
      ];
      return data;
    },
    titleData() {
      const data = {
        title: this.lang.manageCer,
      };
      return data;
    },
  },
  watch: {
    value(v) {
      const d = v === "" ? "0" : v;
      if (d) this.$refs.cTable.apiOnload();
      this.getCount();
    },
    nValue(v) {
      const d = v === "" ? "0" : v;
      if (d) this.$refs.cTable.apiOnload();
    },
    sValue() {
      this.$refs.cTable.apiOnload();
    },
  },
  mounted() {
    this.columnBread = this.breadMenus;
    this.getTypes();
    this.initDict();
    this.getCount();
    this.$socket.connect();
  },
  beforeDestroy() {
    this.$socket.close();
  },
  methods: {
    showClose() {
      this.$refs.cTable.apiOnload();
    },
    async getCount() {
      const d = this.value === 0 || this.value === "" ? null : this.value;
      const res = await Count(d);
      if (res) {
        this.total = res.totalCount;
        this.online = res.onclineCount;
        this.outline = res.outLineCount;
      }
    },
    getCredentialsId() {
      const d = {
        id: this.deviceId.id,
      };
      getDeviceCredentialsByDeviceId(d).then((res) => {
        return res.credentialsId;
      });
    },
    async initDict() {
      try {
        Object.keys(this.dicts).forEach(async (k) => {
          if (k != "SYSTEM_COMPANY_STATUS" && k != "SYSTEM_SOURCETYPE") {
            const res = await getDict(k);
            if (res) {
              this.dicts[k] = res.map((item) => ({
                label: item.name,
                value: item.type + "_" + item.value,
              }));
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async onload(page, query) {
      try {
        const pn = page.pageNum;
        const ps = page.pageSize;
        const row = page.row;
        const p = {
          limit: ps,
          textSearch: this.nValue,
          status:this.sValue,
          type: this.value,
          pageNum: pn,
        };
        let list = [];
        const res = await getTenantDevices(p);
        if (res.data) {
          if (!!this.nValue && this.nValue === "_") {
            list = res.data.filter((item) => {
              return (
                String(item.name).indexOf(this.nValue) > -1 ||
                String(item.code).indexOf(this.nValue) > -1
              );
            });
          } else {
            list = res.data;
          }

          const on = list.filter((res) => {
            return res.status === "0"; // status :0 在线 1 离线
          });
          let reg = /[`~!@#$%^&*()+<>?:"{},.\/;'[\]]/im;
          const testValue = reg.test(this.nValue);
          return {
            list: testValue
              ? []
              : list.map((item) => ({
                  ...item,
                  id: item.id.id,
                  nId: item.id,
                  sourceId: item.id,
                  desc: item.additionalInfo
                    ? item.additionalInfo.description
                    : "",
                })),
            total: testValue ? 0 : res.totalCount || 0,
          };
        }
        return {};
      } catch (e) {
        console.log(e);
      }
      return {};
    },
    // protocolChange(list, prop) {
    //   const p = this.dicts.protocol;
    //   if (!Array.isArray(p) || p.length == 0) return undefined;
    //   list.forEach((l) => {
    //     p.forEach((el) => {
    //       if (l[prop] === el.value) {
    //         l[prop] = el.label;
    //       }
    //     });
    //   });
    //   return list;
    // },
    async getData(params = {}) {
      try {
        const res = await getTenantDevices(params);
        if (res) {
          return res;
        } else {
          this.$message.error(this.lang.getListError);
        }
      } catch (e) {
        console.log(e);
      }
      return { data: [] };
    },
    async getTypes() {
      try {
        this.deviceTypes = await getDeviceType();
      } catch (e) {
        console.log(e);
      }
    },
    // 认证
    confCredent(v) {
      if (this.show && this.token === v.credentialsId) {
        this.$message({ message: this.lang.tokenError, type: "warning" });
        return;
      }
      if (!this.show && this.token === v.credentialsValue) {
        this.$message({ message: this.lang.RSAError, type: "warning" });
        return;
      }

      const d = {
        credentialsId: v.credentialsId,
        credentialsType: v.credentialsType || "",
        credentialsValue: v.credentialsValue || "",
        deviceId: {
          id: this.deviceId.id,
          entityType: this.deviceId.entityType,
        },
        id: this.tokenId,
      };
      saveDeviceCredentials({ ...d })
        .then((res) => {
          this.$message({
            message: this.lang.succAuth,
            type: "success",
          });
          this.$refs.titleDialog.dialogTableVisible = false;
          this.$refs.titleDialog.form = {};
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // hookType(v) {
    //   const p = v === "1" ? "X509_CERTIFICATE" : "ACCESS_TOKEN";
    //   return p;
    // },
    setOk() {
      this.$refs.cTable.apiOnload();
    },
    addClient() {
      this.cData.title = this.lang.addTer;
      this.cData.label = "0";
      this.cData.rowData = this.deviceTypes;
      this.$refs.clientDialog.dialogVisible = true;
    },
    // 查看
    async rowView(col) {
      this.clientData = col;
      this.deviceId = col.nId;
      const d = {
        id: this.deviceId.id,
      };

      this.clientData.sign = "1";
      const c = await getDeviceCredentialsByDeviceId(d);
      this.clientData.credential = c.credentialsId;
      this.$refs.clientDrawer.drawer = true;
    },
    async rowConfirm(col) {
      this.deviceId = col.nId;
      const d = {
        id: this.deviceId.id,
      };
      const c = await getDeviceCredentialsByDeviceId(d);
      this.show = c.credentialsType.toUpperCase().indexOf("ACCESS") > -1;
      this.rowD = c;
      // if (this.show) {
      //   this.rowD = {
      //     credentialsType: c.credentialsType,
      //     credentialsId: c.credentialsId,
      //   };
      // } else {
      //   this.rowD = {
      //     credentialsType: c.credentialsType,
      //     credentialsValue: c.credentialsValue,
      //   };
      // }
      this.token = this.show ? c.credentialsId : c.credentialsValue;
      this.tokenId = c.id;
      this.$refs.titleDialog.dialogTableVisible = true;
    },
    rowDelete(col) {
      if (col.source === "1") return;
      this.$confirm(this.lang.delMsg, this.lang.tips, {
        confirmButtonText: this.lang.confirm,
        cancelButtonText: this.lang.cancel,
        type: "warning",
      })
        .then(() => {
          delDevice(col.id).then((res) => {
            this.$message({
              type: "success",
              message: this.lang.delSuccess,
            });
            this.$refs.cTable.apiOnload();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.client-manager {
  width: 100%;
  height: 100%;
  // position: relative;
  overflow: hidden;
  .client-select {
    padding: 0px 24px 24px 24px;
    display: flex;
    align-items: center;
    .client-line {
      display: inline-block;
      margin-left: 30px;
      width: 1px;
      height: 32px;
      border-radius: 20%;
      background-color: #ddd;
    }
    .client-s {
      color: #4c4c4c;
      margin-left: 30px;
      .circle-green {
        display: inline-block;
        margin-right: 10px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #00ffa5;
        border: 1px solid #09df6d;
      }
      .circle-grey {
        display: inline-block;
        margin-right: 10px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #f0f2f5;
        border: 1px solid #b0b6c3;
      }
      .num {
        display: inline-block;
        margin-left: 10px;
        font-size: 18px;
      }
    }
  }
  .client-index {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .client-line {
    margin: 0px 8px;
    display: inline-block;
    width: 1px;
    height: 12px;
    background: #ddd;
  }
  .status {
    .status-green {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #3abf3a;
      border-radius: 50%;
      margin-right: 5px;
    }
    .status-grey {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #c1b9b9;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
}
</style>
