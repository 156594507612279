<template>
  <div class="els-bread">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <template v-if="column && column.length != 0">
        <!-- <el-breadcrumb-item :to="{ path: '/hanyun-iot/home' }" -->
        <el-breadcrumb-item :to="{ name: 'Home' }">{{
          notShowLogo? $t("d.jiuYuanTitle") : $t("d.platformName")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item
          v-for="(item, ins) of column"
          :key="ins + item.name"
          ><a @click="changePath(item)">{{ item.name }}</a></el-breadcrumb-item
        >
      </template>
    </el-breadcrumb>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "els-bread",
  props: {
    breadColumn: Array,
  },
  computed: {
    ...mapState(["breadMenus"]),
    column(){
      return this.breadColumn || this.breadMenus;
    },
    notShowLogo() {
      return  process.env.VUE_APP_NOT_SHOW_LOGO
    }
  },
  methods: {
    changePath(item) {
      const {path} = item;
      if(/^http/.test(path)){
        window.open(path,'_blank')
      }else{
        this.$router.push({path})
      }
      // const b = this.breadColumn[this.breadColumn.length - 1];

      // let pathArr = [];
      // const ins = this.breadMenus.filter((item) => item.name === val.name);
      // if (b[0].name !== ins[0].name) {
      //   // pathArr = this.breadMenus.splice(0, ins + 1);
      //   // console.log("pathArr", pathArr);
      //   // this.$store.commit("setBreadMenus", pathArr);
      //   this.$router.push(val.path);
      // }
    },
  },
  // mounted() {
  //   console.log("++++++",this.breadMenus);
  //   this.breadMenus = this.$store.getters.breadMenus;
  // },
};
</script>
