// import {getDict} from "@/api/base/dict.js";
import { has } from "loadsh"
export default {
  data() {
    return {
      dicts: {},
    };
  },
  methods: {
    // 当 dicts 数据获取完成后，调用 initDict 初始化需要的数据
    initDict() {},
    //
    mixinsInitDicts() {
      const dict = this.$t("DICT");
      const ks = Object.keys(this.dicts);
      ks.forEach((k, i) => {
        if (has(dict,k) && this.dicts[k].length == 0) {
          this.dicts[k] = this.mixinsParseDict(dict[k]);
          if (i === ks.length - 1) {
            this.initDict();
          }
        }
      });
    },
    mixinsParseDict(list, labelKey = "value", valueKey = "key") {
      let res = [];
      if (!Array.isArray(list) || list.length == 0) return undefined;
      list.forEach((li) => {
        let o = { ...li };
        o.label = o[labelKey];
        o.value = o[valueKey];
        delete o.key;
        res.push(o);
      });
      return res;
    },
    // mixinsReplaceDict(list){
    //   list.forEach((li,i) => {
    //     const dn = li.dictName;
    //     const dk = li.dictKey || "cls";
    //     if(dn !== undefined && this.dicts[dn]){
    //       list[i][dk] = this.dicts[dn]
    //     }
    //   })
    // }
  },
  created() {
    this.mixinsInitDicts();
  },
};
