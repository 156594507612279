<template>
  <div class="client-build" v-if="dialogVisible">
    <el-dialog
      :title="data.title"
      :visible.sync="dialogVisible"
      width="550px"
      @close="close"
      :close-on-click-modal="false"
    >
      <els-form
        ref="elsform"
        :column="column"
        v-model="form"
        :ctx="{ lang: $t('d') }"
      ></els-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">{{ $t("d.cancel") }}</el-button>
        <el-button type="primary" @click="handClick" :loading="isSure">{{
          $t("d.confirm")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import elsForm from "../../../components/els-form/main.vue";
import { getIndustry, saveAsset } from "../../../api/base/client";
import { treeMap } from "../../../utils/tree";
import { timeset } from "../../../utils/client";
import { getDeviceType, saveDevice } from "@/api/manager/client-manage.js";
export default {
  name: "client-build",
  components: { elsForm },
  props: {
    clientColumn: Array,
    data: Object,
  },
  data: () => {
    return {
      isSure: false,
      dialogVisible: false,
      // firstIndustry: [],
      // secondIndustry: [],
      industry: [],
      deviceType: [],
      form: {},
    };
  },
  watch: {
    dialogVisible(v) {
      if (v) this.form = {};
      this.deviceType = [];
    },
    // "data.rowData": {
    //   handler(v) {
    //     if (v) {
    //       v.forEach((item) => {
    //         this.deviceType.push({
    //           label: item.deviceTypeName,
    //           value: item.id.id,
    //         });
    //       });
    //     }
    //   },
    // },
    // "data.industry": {
    //   handler(v) {
    //     if (v) this.industry = v;
    //   },
    // },
    // deep: true,
  },
  computed: {
    column() {
      // let col = treeMap(
      //   this.clientColumn,
      //   (n) => {
      //     // if (n.prop && n.prop === "firstLevelIndustry") {
      //     //   n.cls = this.firstIndustry;
      //     // } else if (n.prop === "secondLevelIndustry") {
      //     //   n.cls = this.secondIndustry;
      //     // } else
      //     // if (n.prop === "netStyle") {
      //     //   n.cls = this.deviceType;
      //     // }
      //   },
      //   { children: "cls" }
      // );
      return this.clientColumn;
    },
  },
  methods: {
    async getInfo() {
      const data = await getIndustry({ assetName: "" });
      this.industry = data;
      data.forEach((item) => {
        if (!!item.fatherNode || item.industryLevel === "1") {
          this.secondIndustry.push({
            id: item.id,
            fatherNode: item.fatherNode,
            level: item.industryLevel,
            label: item.industryName,
            value: item.id.id,
          });
        } else {
          this.firstIndustry.push({
            id: item.id,
            fatherNode: item.fatherNode,
            level: item.industryLevel,
            label: item.industryName,
            value: item.id.id,
          });
        }
      });
    },
    // async getTypes() {
    //   try {
    //     const res = await getDeviceType();
    //     res.forEach((item) => {
    //       this.deviceType.push({
    //         label: item.deviceTypeName,
    //         value: item.id.id,
    //       });
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    async handClick() {
      this.isSure = true;
      const app = this.$refs.elsform;
      try {
        app && (await app.validate());
        if (this.data && this.data.label === "1") {
          //资产管理
          const taglib = JSON.stringify(this.form.selfSource);
          const asset = {
            // label: this.form.label,
            code: this.form.code,
            name: this.form.name,
            type: this.form.type,
            sourceType: 0,
            additionalInfo: this.form.additionalInfo,
            firstLevelIndustry: this.serachIndustry(
              this.form.firstLevelIndustry
            ),
            secondLevelIndustry: this.serachIndustry(
              this.form.secondLevelIndustry
            ),
            taglib: taglib,
            createdTime: timeset(),
          };
          saveAsset({ ...asset })
            .then((res) => {
              this.isSure = false;
              this.$message({
                message: "新建成功",
                type: "success",
              });
              this.dialogVisible = false;
              this.$emit("setSuccess", "");
              this.close();
            })
            .catch((err) => (this.isSure = false));
        } else {
          // 设备管理
          const taglib = JSON.stringify(this.form.selfTag);
          const d = {
            code: this.form.code,
            label: this.form.code,
            name: this.form.name,
            type: this.form.netStyle,
            source: 0,
            simNumber: this.form.simNumber,
            createdTime: timeset(),
            taglib: taglib,
            additionalInfo: { gateway: this.form.gateway },
          };
          saveDevice({ ...d })
            .then((res) => {
              if (res) {
                this.isSure = false;
                this.$message({
                  message: "新建成功",
                  type: "success",
                });
                this.$emit("setSuccess", "");
              }
              this.dialogVisible = false;
            }, 2000)
            .catch((err) => (this.isSure = false));
        }
      } catch (error) {
        console.error(error);
        setTimeout(() => {
          this.isSure = false;
        }, 1000);
      }
    },
    serachIndustry(v) {
      // const item = this.industry.filter((e) => {
      const item = this.data.industry.filter((e) => {
        return (e.id.id = v);
      });
      if (item) {
        const id = item[0].id.id;
        return id;
      }
    },
    close() {
      this.$refs.elsform.resetFormData();
      this.$refs.elsform.clearValidate();
      this.dialogVisible = false;
    },
  },
  created() {
    // if(this.data.industry){
    //   this.industry = this.data.industry
    // }
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-select {
  width: 100%;
}
</style>
